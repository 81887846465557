import Flickity from 'flickity-imagesloaded';
import {gsap} from 'gsap';
import {MotionPathPlugin} from 'gsap/MotionPathPlugin';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import Cookies from 'js-cookie';
import MicroModal from 'micromodal';
import Plyr from 'plyr';

window.gsap = gsap;
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
window.ScrollTrigger = ScrollTrigger;
window.MotionPathPlugin = MotionPathPlugin;

window.Flickity = Flickity;
window.Plyr = Plyr;
window.Cookies = Cookies;
window.MicroModal = MicroModal;
